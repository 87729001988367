export default class LocalStorageUtils {
    //设置token
    static setToken (token, userSign){
        localStorage.setItem("userSign", userSign);
        localStorage.setItem("token", token);
    }

    static getInstance() {
        if (!LocalStorageUtils.instance) {
            LocalStorageUtils.instance = new LocalStorageUtils();
            return LocalStorageUtils.instance
        }
        return LocalStorageUtils.instance;
    }

    constructor() {
        this.token = localStorage.getItem("token");
        this.userSign = localStorage.getItem("userSign");
    }

}

LocalStorageUtils.prototype.removeToken = function (){
    localStorage.removeItem("token")
    localStorage.removeItem("userSign");
    this.token = undefined;
    this.userSign = undefined;
}

LocalStorageUtils.prototype.getToken = function () {
    return this.token;
}

LocalStorageUtils.prototype.getItem = function(key) {
    return localStorage.getItem(`${key}_${this.userSign}`);
}

LocalStorageUtils.prototype.setItem = function (key, value) {
    localStorage.setItem(`${key}_${this.userSign}`, value);
}

LocalStorageUtils.prototype.getJSONItem = function (key) {
    return JSON.parse(this.getItem(key))
}

LocalStorageUtils.prototype.setJSONItem = function (key, value) {
    this.setItem(key, JSON.stringify(value));
}


LocalStorageUtils.prototype.removeItem = function(key) {
    return localStorage.removeItem(`${key}_${this.userSign}`);
}
