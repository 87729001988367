import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from 'react';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import request from "../../services/request";
import LocalStorageUtils from "../../utils/localStorageUtils";
import {
    AddWrongBookButton,
    CancelReviewButton,
    EditContentButton,
    FinishReviewButton,
    TitleShowButton
} from "./OperationButton";
import {AlertContext} from "../base/AlertBox";
import {ContentTypeShow, PlanStateShow} from "./StateAndTypeShow";
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import '../../css/table-style.css'
import {cellWeight, smCellWeight, xsCellWeight} from "./TodayReviewPlan";
import useMediaQuery from '@mui/material/useMediaQuery';



function NotReviewPlan(props, ref) {

    //计划列表数据
    const [data, setData] = useState([]);

    //提示框
    const {alertOpen} = useContext(AlertContext)

    const matches = useMediaQuery('(min-width:900px)');

    //获取列表数据
    function initData() {
        request({
            url: '/review/getBeforeNoReviewPlan',
            method: 'get',
            data: JSON.stringify({})
        }).then(response => {
            if(response.code === 200){
                setData(response.data)
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    useEffect(() => {
        //获取列表数据
        if (LocalStorageUtils.getInstance().getToken()){
            initData();
        }
    }, [])

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        refreshData: initData
    }));

    function operation(row) {
        return (
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems="center"
                   spacing={0} >
                <AddWrongBookButton contentId={row.reviewContent.id} />
                <EditContentButton contentId={row.reviewContent.id} contentType={row.reviewContent.type} onSuccess={initData}/>
                <CancelReviewButton planId={row.id} onSuccess={initData}/>
                <FinishReviewButton planId={row.id} onSuccess={initData} />
            </Stack>
        )
    }

    return (
        <>
            <Grid container spacing={0} className="table-row-title">
                <Grid item md={matches ? cellWeight[0] : 2} sm={matches ? cellWeight[0] : 2} xs={matches ? cellWeight[0] : 2} className="table-cell-title">
                    <div className="table-text">标题</div>
                </Grid>
                <Grid item md={matches ? cellWeight[1] : 2} sm={matches ? cellWeight[1] : 2} xs={matches ? cellWeight[1] : 2} className="table-cell-title">
                    <div className="table-text">类型</div>
                </Grid>
                <Grid item md={matches ? cellWeight[2] : 2} sm={matches ? cellWeight[2] : 2} xs={matches ? cellWeight[2] : 2} className="table-cell-title">
                    <div className="table-text">轮次</div>
                </Grid>
                <Grid item md={matches ? cellWeight[3] : 2} sm={matches ? cellWeight[3] : 2} xs={matches ? cellWeight[3] : 2} className="table-cell-title">
                    <div className="table-text">时间</div>
                </Grid>
                <Grid item md={matches ? cellWeight[4] : 2} sm={matches ? cellWeight[4] : 2} xs={matches ? cellWeight[4] : 2} className="table-cell-title">
                    <div className="table-text">状态</div>
                </Grid>
                <Grid item md={matches ? cellWeight[5] : 2} sm={matches ? cellWeight[5] : 2} xs={matches ? cellWeight[5] : 2} className="table-cell-title">
                    <div className="table-text" style={{textAlign: 'center'}}>操作</div>
                </Grid>
            </Grid>
            <TransitionGroup>
                {data.map((row, index) => (
                    <Collapse key={row.id} >
                        <Grid container className="table-row" spacing={0} sx={{background: index % 2 === 0 ? '#f7f8fa': '#ffffff'}} >
                            <Grid item md={cellWeight[0]} sm={smCellWeight[0]} xs={xsCellWeight[0]} className="table-cell">
                                <TitleShowButton contentId={row.reviewContent.id} contentType={row.reviewContent.type} title={row.reviewContent.name} content={row.reviewContent.content} />
                            </Grid>
                            <Grid item md={cellWeight[1]} sm={smCellWeight[1]} xs={xsCellWeight[1]} className="table-cell"><ContentTypeShow type={row.reviewContent.type} /></Grid>
                            <Grid item md={cellWeight[2]} sm={smCellWeight[2]} xs={xsCellWeight[2]} className="table-cell">{row.currentRounds}/{row.reviewContent.rounds}</Grid>
                            <Grid item md={cellWeight[3]} sm={smCellWeight[3]} xs={xsCellWeight[3]} className="table-cell">{moment(row.reviewContent.createTime).format('YY/MM/DD')}</Grid>
                            <Grid item md={cellWeight[4]} sm={smCellWeight[4]} xs={xsCellWeight[4]} className="table-cell"><PlanStateShow state={row.state} /></Grid>
                            <Grid item justifyContent="flex-end" md={cellWeight[5]} sm={smCellWeight[5]} xs={xsCellWeight[5]} className="table-cell">{operation(row)}</Grid>
                        </Grid>
                    </Collapse>
                ))}
            </TransitionGroup>
        </>
    );
}

export default forwardRef(NotReviewPlan)