import {useState, useEffect, useRef} from 'react';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import preview from "../utils/previewHtml";
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import request from "../services/request";
import RefreshButton from "../components/RefreshButton";

function Edit() {

    const { contentId } = useParams();

    const saveButton = useRef();

    const [title, setTitle] = useState()

    //编辑状态
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))

    //全屏显示的控制器
    const fullscreenControls = [
        {
            key: 'save-button',
            type: 'button',
            title: '保存',
            // text: <SdStorageOutlinedIcon fontSize="small"/>,
            text: <RefreshButton refresh={uploadContent} ref={saveButton}/>,
        },
        'undo', 'redo', 'separator',
        'font-size', 'line-height', 'letter-spacing', 'separator',
        'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
        'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'separator', 'hr', 'separator',
        'media', 'separator',
        'clear',
        {
            key: 'preview-button',
            type: 'button',
            title: '预览',
            text: <VisibilityOutlinedIcon fontSize="small"/>,
            onClick: onPreviewClick
        },
        'fullscreen',
    ]

    function onSave() {
        saveButton.current.execute()
    }

    function uploadContent() {
        //保存到服务器
        request({
            url: '/review/content/update',
            method: 'post',
            data: JSON.stringify({
                "id": contentId,
                "name": title,
                "content": editorState.toHTML()
            })
        }).then(response => {
            if(response.code === 200){

            }else {

            }
        })

    }

    function onPreviewClick() {
        onSave();
        preview(editorState.toHTML(), title);
    }

    //从本地缓存中恢复
    useEffect(() => {
        //远程获取数据
        request({
            url: `/review/content/get?contentId=${contentId}`,
            method: 'get'
        }).then(response => {
            console.log('response', response);
            if(response.code === 200){
                if(response.data){
                    setTitle(response.data.name)
                    //以服务器上的为准
                    setEditorState(BraftEditor.createEditorState(response.data.content));
                    //如果本地和服务器不一样则使用本地恢复
                    // if(LocalStorageUtils.getInstance().getItem(`editorContent_${contentId}`) && LocalStorageUtils.getInstance().getJSONItem(`editorContent_${contentId}`) !== response.data.content){
                    //     setEditorState(BraftEditor.createEditorState(LocalStorageUtils.getInstance().getJSONItem(`editorContent_${contentId}`)));
                    //     onSave();
                    // }else {
                    //     setEditorState(BraftEditor.createEditorState(response.data.content))
                    // }
                }else {
                    setEditorState(BraftEditor.createEditorState('<div style="text-align: center; margin-top: 20px">文档不存在哦~</div>'))
                }
            }else {

            }
        })
    },[])

    //编辑改变回调
    function editorChange(editorState) {
        setEditorState(editorState);
    }

    const myUploadFn = (param) => {
        const formdata = new FormData()
        formdata.append("file", param.file)
        request({
            url: '/file/upload',
            method: 'post',
            headers:{'Content-Type':'multipart/form-data'},
            data: formdata,
            onUploadProgress: progressEvent => {
                const persent = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
                param.progress(persent);
            },
            timeout: 100000000 //一秒钟
        }).then(response => {
            if(response.code === 200){
                param.success({
                    url: response.data,
                    meta: {
                        id: response.data,
                        // title: 'xxx',
                        // alt: 'xxx',
                        // loop: true, // 指定音视频是否循环播放
                        // autoPlay: true, // 指定音视频是否自动播放
                        // controls: true, // 指定音视频是否显示控制栏
                        // poster: 'http://xxx/xx.png', // 指定视频播放器的封面
                    }
                })
            }else {
                param.error({
                    msg: response.data
                })
            }
        })
    }

    return (
        <>
            <TextField
                margin="none"
                fullWidth
                id="title"
                name="title"
                size="small"
                value={title}
                onChange={(event) => {console.log(event.target.value); setTitle(event.target.value)}}
            />
            <BraftEditor
                // className='fullscreen'
                controls={fullscreenControls}
                value={editorState}
                onChange={editorChange}
                onSave={onSave}
                contentStyle={{height: '90vh'}}
                media={{uploadFn: myUploadFn}}
            />
        </>
    )
}

export default Edit