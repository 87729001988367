import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import Badge from '@mui/material/Badge';
import moment from 'moment'
import Schedule from "./Schedule";
import request from "../../services/request";


function Calendar() {
    //选择的日期
    const [value, setValue] = useState(new Date());
    //日程数据
    const [data, setData] = useState([]);

    useEffect(() => {
        request({
            url: '/review/getReviewPlanSchedule',
            method: 'get',
            data: JSON.stringify({})
        }).then(response => {
            if(response.code === 200){
                setData(response.data)
            }
        })
    }, [])

    function onChange(newValue) {
        setValue(newValue);
    }


    //日历日期渲染
    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
        const d = Math.ceil(date.getTime() / (24 * 60 * 60 * 1000)) + 719528;
        for (let i = 0; i < data.length; i++){
            if(data[i]['time'] === d){
                return (
                    <PickersDay
                        {...pickersDayProps}
                        children = {<Badge  color="grey" variant="dot" invisible={false}><div>{moment(date).format("D")}</div></Badge>}
                    />
                );
            }
        }
        return (
            <PickersDay
                {...pickersDayProps}
                children = {<div>{moment(date).format("D")}</div>}
            />
        );
    };
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={value}
                    onChange={onChange}
                    renderDay={renderWeekPickerDay}
                    renderInput={(params) => <TextField {...params} />}
                />
                <Schedule date={value}/>
            </LocalizationProvider>
        </>
    )
}

export default Calendar