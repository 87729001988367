import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import LocalStorageUtils from "../../utils/localStorageUtils";
import DialogBox from "../base/DialogBox";
import ModifyPassword from "./ModifyPassword";
import Feedback from "./Feedback";
import AboutUs from "./AboutUs";

function HeadPortrait(){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [panel, setPanel] = React.useState(0);


    function logout() {
        LocalStorageUtils.getInstance().removeToken();
        window.location.reload();
    }

    return (
        <div>
            <DialogBox open={panel !== 0} setOpen={(open) => {setPanel(0)}} >
                {panel === 1 && <ModifyPassword onCancel={() => {setPanel(0)}}/>}
                {panel === 2 && <Feedback onCancel={() => {setPanel(0)}}/>}
                {panel === 3 && <AboutUs onCancel={() => {setPanel(0)}}/>}
            </DialogBox>
            <Avatar sx={{ bgcolor: '#cccccc' }} onClick={handleClick}>F</Avatar>
            {/*<Tooltip title="添加复习内容">*/}
            {/*    <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClick}>*/}
            {/*        <AddIcon />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => setPanel(1)}>修改密码</MenuItem>
                <MenuItem onClick={() => setPanel(2)}>意见反馈</MenuItem>
                <MenuItem onClick={() => setPanel(3)}>联系我们</MenuItem>
                <MenuItem onClick={logout}>退出登录</MenuItem>
            </Menu>
        </div>
    );
};

export default HeadPortrait