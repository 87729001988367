import {useState, useCallback, useEffect} from 'react'


export function useTimer(){
    const [ date , setDate] = useState(new Date())

    const onChange = useCallback(()=>{
        setDate(new Date())
    },[])

    useEffect(()=>{
        //定时器每分钟执行一次
        const interval = setInterval(onChange, 1000);
        return ()=>{
            clearInterval(interval);
        }
    },[])

    return date;
}