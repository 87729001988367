import React, { Component } from 'react'

import style from './answer-detail.module.scss'

export default function AnswerDetail(props) {
    const { content} = props.data;
    return (
        <div className={style.fullContentWrapper}>
            <div>
                {/*<div className={style.voteCount}>{1}人赞同了该回答</div>*/}
                <div className={style.fullContent} dangerouslySetInnerHTML={{ __html: content }}></div>
                {/*<span className={style.updateTime}> {`编辑于 ${updatedTime}`}</span>*/}
            </div>
        </div >
    )
}