import {useState, useEffect, useContext} from 'react';
import styles from './question-actions.module.scss';
import PenIcon from '../../assets/svg/Pen';
import ThumbUpIcon from '../../assets/svg/ThumbUp'
import AnswerRichTextEditor from "../../answer/answer-edit/AnswerRichTextEditor";
import request from "../../../services/request";
import BraftEditor from 'braft-editor'
import {AlertContext} from "../../../components/base/AlertBox";

export default function QuestionInfoAction({data: {id, title, description, praise, createTime, isPraise}, refresh}) {

    //提示框
    const {alertOpen} = useContext(AlertContext)


    const [showAnswerEdit, setShowAnswerEdit] = useState(false);

    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));

    const switchShowAnswerEdit = () => {
        setShowAnswerEdit(!showAnswerEdit);
    }

    const submitAnswer = () => {
        const content = editorState.toHTML();
        if (content.length === 0){
            alertOpen('error', '请输入答案')
            return;
        }
        request({
            url: `/topic/addAnswer`,
            method: 'post',
            data: JSON.stringify({
                questionId: id,
                content: content
            })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '提交成功，审核通过后自动发布！')
                setEditorState(BraftEditor.createEditorState(null))
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    const switchPraise = () => {
        request({
            url: isPraise === 1 ? '/topic/cancelQuestionPraise' : '/topic/addQuestionPraise',
            method: 'post',
            data: JSON.stringify({
                questionId: id
            })
        }).then(response => {
            if(response.code === 200){
                refresh();
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <>
            <div className={styles.wrapper} >

                <div className={styles.wrapperInner}>
                    <div className={styles.main}>

                        {/*<button className={styles.btnFollow}>*/}
                        {/*    关注问题*/}
                        {/*</button>*/}
                        <button className={styles.btnAnswer} onClick={switchShowAnswerEdit}>
                            <span className={styles.icon}><PenIcon /></span>
                            分享我的想法
                        </button>
                        {/*<button className={styles.btnInvite}>*/}
                        {/*    <span className={styles.icon}><InviteIcon /></span>*/}
                        {/*    邀请回答*/}
                        {/*</button>*/}

                        <button className={styles.btnGood} style={isPraise === 1 ? {color: 'rgba(255, 161, 22, 1)'} : {}} onClick={switchPraise}>
                            <span className={styles.vodeUp}><ThumbUpIcon /></span>
                            {`赞 ${praise}`}
                        </button>
                        {/*<button className={styles.btnComment}>*/}
                        {/*    <span className={styles.icon}><CommentIcon /></span>*/}
                        {/*    {`${commentNum} 条评论`}*/}
                        {/*</button>*/}
                        {/*<button className={styles.btnShare}>*/}
                        {/*    <span className={styles.icon}><ShareIcon /></span>*/}
                        {/*    分享*/}
                        {/*</button>*/}
                        {/*<button className={styles.btnDot}>*/}
                        {/*    <span className={styles.icon}><DotsIcon /></span>*/}
                        {/*</button>*/}
                        {/*{fullContent ? (*/}
                        {/*    <button className={`${styles.btnUp} ${styles.collapseBtn}`} onClick={e => this.handleClose()}>*/}
                        {/*        收起*/}
                        {/*        <span className={styles.icon}><ArrowUp /></span>*/}
                        {/*    </button>*/}
                        {/*) : null}*/}


                    </div>
                    {showAnswerEdit && <>
                        <AnswerRichTextEditor editorState={[editorState, setEditorState]}/>
                        <div className={styles.main}>
                            <button className={styles.btnAnswer} onClick={submitAnswer}>
                                <span className={styles.icon}><PenIcon /></span>
                                提交答案
                            </button>
                        </div>
                    </>}

                </div>

            </div>
        </>
    )
}