import {useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";

export default function Feedback(props) {

    //提示框
    const {alertOpen} = useContext(AlertContext)

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fromData = new FormData(event.currentTarget);

        const describe = fromData.get('describe');
        const contact = fromData.get('contact');

        if (describe === ''){
            alertOpen('error', '请填写问题描述！');
            return;
        }

        if (describe.length > 200){
            alertOpen('error', '问题描述不能大于200字！');
            return;
        }

        if(contact && contact.length > 200){
            alertOpen('error', '联系方式不能大于200字！');
            return;
        }

        request({
            url: '/user/feedback',
            method: 'post',
            data: JSON.stringify({
                describe: describe,
                contact: contact,
            })
        }).then(response => {
            //登录成功
            if(response.code === 200){
                alertOpen('success', '反馈成功，我们会尽快处理！')
                onCancel();
            }else{
                //登录失败
                alertOpen('error', response.msg)
            }
        })
    };

    const onCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    意见反馈
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        id="describe"
                        label="问题描述"
                        name="describe"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="contact"
                        label="联系方式"
                        name="contact"
                        autoComplete="contact"
                        placeholder="请输入邮件、手机号码或QQ号码任意一种联系方式"
                    />
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="记住我"*/}
                    {/*/>*/}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={8}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onCancel}
                        >
                            取消
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            提交反馈
                        </Button>
                    </Stack>

                </Box>
            </Box>
        </Container>
    );
}