import {useState, useEffect} from 'react';
import request from "../../services/request";
import AnswerItem from "./answer-item/AnswerItem";
import { useParams } from 'react-router-dom';
export default function AnswerList(props) {

    const { id } = useParams();

    const [pageNum, setPageNum] = useState(1);

    const [data, setData] = useState([]);

    useEffect(() => {
        initData();
    }, [])

    async function initData() {
        const data = await getData(1);
        setData(data);
    }

    async function getData(pageNum) {
        const {code, data} = await request({
            url: `/topic/getAnswerList?questionId=${id}&pageNum=${pageNum}&pageSize=999`,
            method: 'get'
        })
        if (code === 200){
            return data;
        }else {
            return [];
        }
    }

    return (
        <div>{data.map(item => {
            return <AnswerItem data={item} refresh={initData}/>
        })}</div>
    )
}