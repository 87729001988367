import {useState, useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";
import SendVerificationCodeButton from "./SendVerificationCodeButton";
import sha256 from 'crypto-js/sha256';

export default function ForgetPassword() {

    //提示框
    const {alertOpen} = useContext(AlertContext)

    const [mail, setMail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fromData = new FormData(event.currentTarget);

        const code = fromData.get('code');
        const password = fromData.get('password');
        const password2 = fromData.get('password2');

        if (code === ''){
            alertOpen('error', '请填写验证码！');
            return;
        }

        if (password === ''){
            alertOpen('error', '请填写密码！');
            return;
        }

        if(password !== password2){
            alertOpen('error', '两次密码不一致！');
            return;
        }

        request({
            url: '/user/resetPassword',
            method: 'post',
            data: JSON.stringify({
                mail: mail,
                code: code,
                password: sha256(password).toString(),
            })
        }).then(response => {
            //登录成功
            if(response.code === 200){
                alertOpen('success', '修改成功，去登录吧！');
            }else{
                //登录失败
                alertOpen('error', response.msg)
            }
        })
    };

    const sendVerificationCode = () => {
        const mailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        if (!mailReg.test(mail)){
            alertOpen('error', '邮箱格式错误');
            return false;
        }
        request({
            url: `/user/resetPassword/sendVerificationCode`,
            method: 'post',
            data: JSON.stringify({
                mail: mail
            })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '验证码已发送');
            }else{
                alertOpen('error', response.msg)
            }
        })
        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                {/*    <LockOutlinedIcon />*/}
                {/*</Avatar>*/}
                <Typography component="h1" variant="h5">
                    找回密码
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="邮箱"
                        value={mail}
                        onChange={value => setMail(value.target.value)}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="验证码"
                            name="code"
                            autoComplete="code"
                        />
                        <SendVerificationCodeButton onClick={sendVerificationCode} />
                    </Stack>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="密码"
                        type="password"
                        id="password"
                        autoComplete="password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password2"
                        label="重复密码"
                        type="password"
                        id="password2"
                        autoComplete="password2"
                    />
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="记住我"*/}
                    {/*/>*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        找回密码
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}