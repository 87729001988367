import {useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import request from "../../services/request";
import Container from '@mui/material/Container';


const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function Schedule(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        request({
            url: `/review/getReviewPlanByDate?date=${props.date.getTime()}`,
            method: 'get',
            data: JSON.stringify({})
        }).then(response => {
            if(response.code === 200){
                setData(response.data)
            }
        })
    }, [props.date])

    return (
        <Container sx={{width: '100%', padding: '0', background: '#ffffff', minHeight: '200px', paddingBottom: '60px'}}>
            <Demo sx={{ flexGrow: 1, width: '320px', marginTop: '-50px', marginBottom: '70px', margin: 'auto'}}>
                <List>
                    {data.map(item => {
                        return (
                            <div key={item.id}>
                                <Divider />
                                <ListItem>
                                    <ListItemText primary={`${item.currentRounds}/${item.reviewContent.rounds} ${item.reviewContent.name}`} />
                                </ListItem>
                            </div>
                        )
                    })}
                </List>
            </Demo>
        </Container>

    )
}

export default Schedule