import {useState, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from "../TabPanel";
import Box from '@mui/material/Box';
import AddTextForm from "./AddTextForm";
import AddLinkForm from "./AddLinkForm";
import LocalStorageUtils from "../../utils/localStorageUtils";
import LinkIcon from '@mui/icons-material/Link';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

function AddForm({refresh}) {
    //选项卡切换
    const [value, setValue] = useState(0);

    //切换Tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
        LocalStorageUtils.getInstance().setJSONItem('addWin', newValue);
    };

    //从缓存中读取Tab
    useEffect(() => {
        if(LocalStorageUtils.getInstance().getItem("addWin")){
            setValue(LocalStorageUtils.getInstance().getJSONItem('addWin'));
        }
    }, [])

    return (
        <Box sx={{ backgroundColor: '#ffffff', width: '100%' }}>
            {/*顶部导航栏*/}
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="文本" icon={<TextSnippetOutlinedIcon/>} iconPosition="start" id="simple-tab-0" aria-controls="simple-tabpanel-0" sx={{width: '50%'}}/>
                <Tab label="链接" icon={<LinkIcon />} iconPosition="start" id="simple-tab-1" aria-controls="simple-tabpanel-1" sx={{width: '50%'}}/>
            </Tabs>
            {/*主体部分*/}
            <TabPanel value={value} index={0}>
                <AddTextForm refresh={refresh}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AddLinkForm refresh={refresh}/>
            </TabPanel>
        </Box>
    )
}

export default AddForm