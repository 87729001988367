import {useState, useEffect} from 'react';
import request from "../../../services/request";
import { useParams } from 'react-router-dom';
import style from "./question-info.module.scss";
import QuestionInfoAction from "../question-action/QuestionInfoAction";

export default function QuestionInfo() {

    const [data, setData] = useState({});

    const { id } = useParams();

    useEffect(() => {
       init();
    }, [])

    const init = () => {
        request({
            url: `/topic/getQuestion?questionId=${id}`,
            method: 'get'
        }).then(response => {
            console.log('response', response);
            if(response.code === 200){
                if(response.data){
                    setData(response.data)
                }else {

                }
            }else {

            }
        })
    }

    const [fullContent, setFullContent] = useState(false);

    const {title, description, praise, createTime, isPraise} = data;

    return (
        <div className={style.questionHeader}>
            {/* 问题描述 */}
            <div className={style.questionInfo}>
                <div className={style.questionMain}>
                    <div><h1>{title}</h1></div>
                    <div className={`${style.info} ${fullContent ? style.fullContent : null}`}
                         onClick={() => {}}>
                        {description}
                    </div>
                </div>
                {/*<div className={style.questionSide}>side</div>*/}
            </div>
            {/* 问题操作栏 */}
            <div className={style.questionFooter}>
                <QuestionInfoAction data={data} refresh={init}/>
            </div>
        </div>
    )
}