import {useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";
import sha256 from 'crypto-js/sha256';
import LocalStorageUtils from "../../utils/localStorageUtils";

export default function ModifyPassword(props) {

    //提示框
    const {alertOpen} = useContext(AlertContext)

    const handleSubmit = async (event) => {
        event.preventDefault();

        const fromData = new FormData(event.currentTarget);

        const oldPassword = fromData.get('oldPassword');
        const password = fromData.get('password');
        const password2 = fromData.get('password2');

        if (oldPassword === ''){
            alertOpen('error', '请填写原密码！');
            return;
        }

        if (password === ''){
            alertOpen('error', '请填写新密码！');
            return;
        }

        if(password !== password2){
            alertOpen('error', '两次密码不一致！');
            return;
        }

        request({
            url: '/user/modifyPassword',
            method: 'post',
            data: JSON.stringify({
                originalPassword: sha256(oldPassword).toString(),
                newPassword: sha256(password).toString(),
            })
        }).then(response => {
            //登录成功
            if(response.code === 200){
                alertOpen('success', '修改成功，请重新登录！');
                LocalStorageUtils.getInstance().removeToken();
                window.location.reload();
            }else{
                //登录失败
                alertOpen('error', response.msg)
            }
        })
    };

    const onCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                {/*    <LockOutlinedIcon />*/}
                {/*</Avatar>*/}
                <Typography component="h1" variant="h5">
                    修改密码
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="oldPassword"
                        label="原密码"
                        type="password"
                        id="oldPassword"
                        autoComplete="oldPassword"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="新密码"
                        type="password"
                        id="password"
                        autoComplete="password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password2"
                        label="重复新密码"
                        type="password"
                        id="password2"
                        autoComplete="password2"
                    />
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="记住我"*/}
                    {/*/>*/}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={8}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onCancel}
                        >
                            取消
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            修改
                        </Button>
                    </Stack>

                </Box>
            </Box>
        </Container>
    );
}