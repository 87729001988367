import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DraggableDialog(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            // PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                提示
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    确定删除内容和复习计划吗？
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose}>
                    取消
                </Button>
                <Button onClick={props.handleOK}>确定</Button>
            </DialogActions>
        </Dialog>
    );
}