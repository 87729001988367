import {useState, useImperativeHandle, forwardRef} from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../css/refresh.css'
function RefreshButton(props, ref) {

    const [className, setClassName] = useState('no-refresh-icon');

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        execute: onClick
    }));

    async function onClick (e) {
        // e.stopPropagation();
        await setClassName('refresh-icon');
        if(props.refresh){
            await props.refresh();
        }
        setTimeout( function(){
            setClassName('no-refresh-icon');
        }, 1000 )
    }

    return <RefreshIcon sx={{width: '20px', height: '20px'}} size="small" className={className} onClick={e => onClick(e)} />
}

export default forwardRef(RefreshButton);