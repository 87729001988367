import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
    return (
        <Typography sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
            <Link  color="inherit" href="https://beian.miit.gov.cn/">
                京ICP备2021021744号
            </Link>
        </Typography>
    );
}
function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
            }}
        >
                <Copyright />
        </Box>
    )
}

export default Footer