import React from 'react';
import ReactDOM from 'react-dom';
import AlertBox from "./components/base/AlertBox";
import AppTheme from './components/base/AppTheme';
import AppRouter from "./routes/AppRouter";


ReactDOM.render(<AppTheme>
    <AlertBox>
        <AppRouter />
    </AlertBox>
</AppTheme>, document.getElementById('root'));
