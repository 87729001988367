import React, {useEffect} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import LocalStorageUtils from "../../utils/localStorageUtils";


const roundsArr = [
    {title: '5分', time: 5},
    {title: '30分', time: 30},
    {title: '12时', time: 12 * 60},
    {title: '1天', time: 24 * 60},
    {title: '2天', time: 2 * 24 * 60},
    {title: '4天', time: 4 * 24 * 60},
    {title: '7天', time: 7 * 24 * 60},
    {title: '15天', time: 15 * 24 * 60},
    {title: '30天', time: 30 * 24 * 60},
    {title: '60天', time: 60 * 24 * 60},
    {title: '120天', time: 120 * 24 * 60},
    {title: '1年', time: 365 * 24 * 60},]

function RoundsGroup(props) {
    //轮次
    const {rounds, setRounds} = props.rounds;

    //从缓存中恢复
    useEffect(() => {
        const checkedRounds = LocalStorageUtils.getInstance().getItem("checkedRounds") ? LocalStorageUtils.getInstance().getJSONItem("checkedRounds") : roundsArr.map(item => item.time);
        if(checkedRounds){
            setRounds(checkedRounds);
        }
    }, [])

    //轮次勾选改变
    function roundsChange(event, time){
        if(event.target.checked){
            const arr = [...new Set([...rounds, time])]
            setRounds(arr)
            LocalStorageUtils.getInstance().setJSONItem("checkedRounds", arr)
        }else {
            const arr = [...rounds];
            arr.splice(arr.indexOf(time), 1)
            setRounds(arr)
            LocalStorageUtils.getInstance().setJSONItem("checkedRounds", arr)
        }
    }

    return (
        <FormGroup>
            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start" >
                {
                    roundsArr.map((item) => {
                        return (
                            <Grid item xs={4} key={item.time}>
                                <FormControlLabel control={<Checkbox checked={rounds.indexOf(item.time) !== -1} onChange={(event) => roundsChange(event, item.time)} size="small"/>} label={item.title} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </FormGroup>
    )
}

export default RoundsGroup