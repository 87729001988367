import {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {useTimer} from "../../utils/useTimer";
import PropTypes from 'prop-types';

export default function SendVerificationCodeButton(props) {
    //按钮可用倒计时
    const [countDown, setCountDown] = useState(0);

    const time = useTimer();

    useEffect(() => {
        if(countDown > 0){
            setCountDown(countDown - 1);
        }
    }, [time])

    function onClick() {
        if(props.onClick ){
            if(!props.onClick()){
                return;
            }
        }
        setCountDown(60)

    }


    return (
        <Button
            // type="submit"
            onClick={onClick}
            disabled = {countDown > 0}
            variant="contained"
            sx={{ mt: 3, mb: 2 , minWidth: '140px'}}
        >
            {countDown > 0 ? `${countDown} s` : '发送验证码'}
        </Button>
    )
}

SendVerificationCodeButton.propTypes = {
    onClick: PropTypes.func.isRequired
};