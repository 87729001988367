import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ffffff',
        color: '#000000',
        fontWeight: 'normal',
        // minWidth: '30px'
        padding: '16px 2px',
        textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        minWidth: '60px'
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f7f8fa',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export const StyledGridItem = styled(Grid)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ffffff',
        color: '#000000',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        minWidth: '60px'
    },
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f7f8fa',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));