import {useState, useEffect, useRef} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Calendar from "../components/calendar/Calendar";
import Tabs from '@mui/material/Tabs';
import TabPanel from "../components/TabPanel";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TodayReviewPlan from "../components/content/TodayReviewPlan";
import HeadPortrait from "../components/auth/HeadPortrait";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import AddForm from "../components/add/AddForm";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import WrongBook from "../components/content/WrongBook";
import LocalStorageUtils from "../utils/localStorageUtils";
import CustomTab from "../components/CustomTab";
import AllReviewContent from "../components/content/AllReviewContent";
import SearchReviewContent from "../components/SearchReviewContent";
import Auth from "../components/auth/Auth";
import useMediaQuery from '@mui/material/useMediaQuery';
import Discussion from "../components/discussion/Discussion";
import QuestionInfo from "./question/question-info/QuestionInfo";
import AnswerList from "./answer/AnswerList";

function ForumIndex() {
    //打卡登录窗口
    const [openLoginWin, setOpenLoginWin] = useState(false);
    useEffect(()=>{
        const token = LocalStorageUtils.getInstance().getToken();
        if(!token){
            setOpenLoginWin(true)
        }
    }, [])

    return (
        <>
            <Auth onClose={() => {}} open={openLoginWin} />
            <Box sx={{ backgroundColor: '#f7f8fa', width: '100%' }}>
                {/*顶部导航栏*/}
                <AppBar position="fixed" sx={{boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 2%), 0px 1px 10px 0px rgb(0 0 0 / 1%)'}}>
                    <Box sx={{ backgroundColor: '#ffffff', padding: '4px', height: '48px'}}>
                        <Container fixed>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <Logo />
                                </Grid>
                                <Grid item xs={8} sm={8} md={6} lg={6}>

                                </Grid>
                                <Grid item xs={2} sm={2} md={4} lg={4}>
                                    <Stack
                                        sx={{height: '100%'}}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}>
                                        <HeadPortrait />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </AppBar>
                <Toolbar id="back-to-top-anchor" />

                {/*主体部分*/}
                <Container fixed>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Box sx={{ backgroundColor: '#ffffff' }}>
                                <QuestionInfo />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={8.5} >
                            <Box sx={{ minHeight: '100vh', backgroundColor: '#ffffff' }}>
                                <AnswerList />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3.5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} >
                                    <Discussion />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </Box>
        </>
    )
}

export default ForumIndex