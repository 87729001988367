import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

function CircleBox(props) {
    return <span style={{display: 'block', width: '10px', height: '10px', borderRadius: '50%', background: props.color, margin: 'auto 6px auto 0'}} {...props}></span>
}

/**
 * 状态显示
 */
//内容状态显示
export function ContentStateShow(props) {
    if (props.state === 0){
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                sx={{minWidth: '60px'}}>
                <CircleBox color="red"/>
                <span style={{color: "#666666", fontSize: "0.90em"}}>复习中</span>
            </Stack>
        )
    }
    if (props.state === 1){
        return (
            <Stack direction="row"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   spacing={0}
                   sx={{minWidth: '60px'}}>
                <CircleBox color="green"/>
                <span style={{color: "#666666", fontSize: "0.90em"}}>完成</span>
            </Stack>
        )
    }
}
ContentStateShow.propTypes = {
    state: PropTypes.number.isRequired
}
//计划状态显示
export function PlanStateShow(props) {
    if (props.state === 0){
        return (
            <Stack direction="row"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   spacing={0}
                   sx={{minWidth: '80px'}}>
                <CircleBox color="red"/>
                <span style={{color: "#666666", fontSize: "0.90em"}}>待复习</span>
            </Stack>
        )
    }
    if (props.state === 1){
        return (
            <Stack direction="row"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   spacing={0}
                   sx={{minWidth: '60px'}}>
                <CircleBox color="green"/>
                <span style={{color: "#666666", fontSize: "0.90em"}}>完成</span>
            </Stack>
        )
    }
    if (props.state === 2){
        return (
            <Stack direction="row"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   spacing={0}
                   sx={{minWidth: '60px'}}>
                <CircleBox color="green"/>
                <span style={{color: "#666666", fontSize: "0.90em"}}>跳过</span>
            </Stack>
        )
    }
}
PlanStateShow.propTypes = {
    state: PropTypes.number.isRequired
}


/**
 * 类型显示
 */
//内容类型显示
export function ContentTypeShow(props) {
    if(props.type === 0){
        return <Tooltip title="链接"><LinkIcon /></Tooltip>
    }
    if(props.type === 1){
        return <Tooltip title="文本"><TextSnippetOutlinedIcon /></Tooltip>
    }
}
ContentTypeShow.propTypes = {
    type: PropTypes.number.isRequired
}