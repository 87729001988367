import {useState} from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import {EditContentButton} from "./OperationButton";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#eeeeee',
        border: 'none',
        fontSize: 16,
        minWidth: '60px',
        minHeight: '30px',
        padding: 0,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: 'none',
            borderColor: '#eeeeee',
            backgroundColor: '#eeeeee'
        },
    },
}));


//下拉框选择的样式
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
        },
    },
};

//选中的样式
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export function SelectBox(props) {

    const theme = useTheme();

    const [selected, setSelected] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <Warp>
            <FormControl>
                <Select
                    displayEmpty
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selected}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em>{props.label}</em>;
                        }
                        return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                >
                    <MenuItem disabled value="">
                        <em>{props.label}</em>
                    </MenuItem>
                    {props.data && props.data.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selected, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Warp>
    )
}
SelectBox.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    onSelected: PropTypes.func,
}


function Warp(props) {
    return <Box sx={{borderRadius: '6px', backgroundColor: '#eeeeee', minHeight: '38px', textAlign: 'center', display: "flex", alignItems: "center", padding: "0 16px"}}>
        {props.children}
    </Box>
}

export function SearchInput(props) {
    const [value, setValue] = useState("");
    function onChange(e) {
        setValue(e.target.value);
        if(e.target.value === ''){
            props.onSearch(e.target.value);
        }
    }
    function onSearch(e) {
        if(e.nativeEvent.keyCode === 13 && props.onSearch){
            props.onSearch(value);
        }
    }
    return (
        <Warp>
            <BootstrapInput value={value} onKeyPress={onSearch} onChange={onChange} placeholder="搜索标题" id="bootstrap-input" startAdornment={
                <InputAdornment position="start" xs={{backgroundColor: '#fff'}}>
                    <SearchIcon/>
                </InputAdornment>
            }/>
        </Warp>
    )
}

SearchInput.propTypes = {
    onSearch: PropTypes.func
}