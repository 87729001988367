import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from 'react';
import moment from 'moment';
import request from "../../services/request";
import LocalStorageUtils from "../../utils/localStorageUtils";
import {DeleteWrongBookButton, TitleShowButton} from "./OperationButton";
import {AlertContext} from "../base/AlertBox";
import {ContentStateShow, ContentTypeShow} from "./StateAndTypeShow";
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import '../../css/table-style.css'

const cellWeight = [5, 1.5, 2, 1.5, 2]

function AllReviewContent(props, ref) {

    //计划列表数据
    const [data, setData] = useState([]);

    //提示框
    const {alertOpen} = useContext(AlertContext)

    //获取列表数据
    function initData() {
        request({
            url: '/review/getWrongBookList',
            method: 'get',
            data: JSON.stringify({})
        }).then(response => {
            if(response.code === 200){
                setData(response.data)
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    useEffect(() => {
        //获取列表数据
        if (LocalStorageUtils.getInstance().getToken()){
            initData();
        }
    }, [])

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        refreshData: initData
    }));

    function operation(row) {
        return (
            <div style={{margin: "auto"}} >
                <DeleteWrongBookButton wrongBookId={row.id} onSuccess={initData} />
            </div>
        )
    }

    return (
        <>
            <Grid container spacing={0} className="table-row-title">
                <Grid item md={cellWeight[0]} className="table-cell-title">
                    <div className="table-text">标题</div>
                </Grid>
                <Grid item md={cellWeight[1]} className="table-cell-title">
                    <div className="table-text">类型</div>
                </Grid>
                <Grid item md={cellWeight[2]} className="table-cell-title">
                    <div className="table-text">创建时间</div>
                </Grid>
                <Grid item md={cellWeight[3]} className="table-cell-title">
                    <div className="table-text">状态</div>
                </Grid>
                <Grid item md={cellWeight[4]} className="table-cell-title">
                    <div className="table-text" style={{textAlign: 'center'}}>操作</div>
                </Grid>
            </Grid>
            <TransitionGroup>
                {data.map((row, index) => (
                    <Collapse key={row.id} >
                        <Grid container className="table-row" spacing={0} sx={{background: index % 2 === 0 ? '#f7f8fa': '#ffffff'}} >
                            <Grid item md={cellWeight[0]} className="table-cell">
                                <TitleShowButton contentId={row.reviewContent.id} contentType={row.reviewContent.type} title={row.reviewContent.name} content={row.reviewContent.content} />
                            </Grid>
                            <Grid item md={cellWeight[1]} className="table-cell"><ContentTypeShow type={row.reviewContent.type} /></Grid>
                            <Grid item md={cellWeight[2]} className="table-cell">{moment(row.reviewContent.createTime).format('YYYY/MM/DD')}</Grid>
                            <Grid item md={cellWeight[3]} className="table-cell"><ContentStateShow state={row.reviewContent.state} /></Grid>
                            <Grid item md={cellWeight[4]} className="table-cell">{operation(row)}</Grid>
                        </Grid>
                    </Collapse>
                ))}
            </TransitionGroup>
        </>
    );
}

export default forwardRef(AllReviewContent)