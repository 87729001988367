import {useState, useEffect, useRef} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Calendar from "../components/calendar/Calendar";
import Tabs from '@mui/material/Tabs';
import TabPanel from "../components/TabPanel";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TodayReviewPlan from "../components/content/TodayReviewPlan";
import HeadPortrait from "../components/auth/HeadPortrait";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import AddForm from "../components/add/AddForm";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import WrongBook from "../components/content/WrongBook";
import LocalStorageUtils from "../utils/localStorageUtils";
import CustomTab from "../components/CustomTab";
import AllReviewContent from "../components/content/AllReviewContent";
import SearchReviewContent from "../components/SearchReviewContent";
import Auth from "../components/auth/Auth";
import useMediaQuery from '@mui/material/useMediaQuery';

function Home() {
    //选项卡切换
    const [value, setValue] = useState(0);

    const todayReviewPlanRef = useRef();
    const allReviewContentRef = useRef();
    const wrongBookRef = useRef();

    const matches = useMediaQuery('(min-width:600px)');

    //打卡登录窗口
    const [openLoginWin, setOpenLoginWin] = useState(false);
    useEffect(()=>{
        const token = LocalStorageUtils.getInstance().getToken();
        if(!token){
            setOpenLoginWin(true)
        }
    }, [])
    
    function switchTab(index) {
        setValue(index);
        document.body.scrollTo(0, 0);
    }

    function refreshCurrentTab() {
        value === 0 && todayReviewPlanRef.current.refreshData();
        value === 1 && allReviewContentRef.current.refreshData();
        value === 2 && wrongBookRef.current.refreshData();
    }

    return (
        <>
            <Auth onClose={() => {}} open={openLoginWin} />
            <Box sx={{ backgroundColor: '#f7f8fa', width: '100%' }}>
                {/*顶部导航栏*/}
                <AppBar position="fixed" sx={{boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 2%), 0px 1px 10px 0px rgb(0 0 0 / 1%)'}}>
                    <Box sx={{ backgroundColor: '#ffffff', padding: '4px', height: '48px'}}>
                        <Container fixed>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <Logo />
                                </Grid>
                                <Grid item xs={8} sm={8} md={6} lg={6}>
                                    <Tabs value={value} scrollButtons="auto">
                                        <CustomTab onClick={() => switchTab(0)} onRefresh={() => todayReviewPlanRef.current.refreshData()} selected={value === 0} title="今日复习内容"/>
                                        <CustomTab onClick={() => switchTab(1)} onRefresh={() => allReviewContentRef.current.refreshData()} selected={value === 1} title="全部" />/>
                                        <CustomTab onClick={() => switchTab(2)} onRefresh={() => wrongBookRef.current.refreshData()} selected={value === 2} title="错题本"/>
                                    </Tabs>
                                </Grid>
                                <Grid item xs={2} sm={2} md={4} lg={4}>
                                    <Stack
                                        sx={{height: '100%'}}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}>
                                        {matches && <SearchReviewContent />}
                                        <HeadPortrait />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </AppBar>
                <Toolbar id="back-to-top-anchor" />

                {/*主体部分*/}
                <Container fixed>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8} >
                            <Box sx={{ minHeight: '100vh', backgroundColor: '#ffffff' }}>
                                <TabPanel value={value} index={0}>
                                    <TodayReviewPlan ref={todayReviewPlanRef}/>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <AllReviewContent ref={allReviewContentRef} />
                                    {/*<AllReviewContent ref={allReviewContentRef}/>*/}
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <WrongBook ref={wrongBookRef}/>
                                </TabPanel>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Grid container spacing={2}>
                                {/*<Grid item xs={12} sm={12} md={12} >*/}
                                {/*    <Discussion />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={12} md={12} >
                                    <AddForm refresh={refreshCurrentTab}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} >
                                    <Calendar />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </Box>
        </>
    )
}

export default Home



// import CountdownTimer from "./components/CountdownTimer";
// import RichTextEditor from "./components/RichTextEditor";
//
// function Home() {
//     //10.10 12:00
//     const endTime = new Date(1637287200 * 1000 + 60 * 1000 * 60 * 2);
//     return (
//         <>
//             {/*<CountdownTimer endTime={endTime}/>*/}
//             {/*<CountdownTimer endTime={endTime}/>*/}
//             <RichTextEditor />
//         </>
//     )
// }
//
// export default Home