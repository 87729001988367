import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Edit from "../pages/edit";
import Details from "../pages/details";
import Home from "../pages/home";
import Test from "../pages/test";
import ForumIndex from "../forum";

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/edit/:contentId" element={<Edit />} />
                <Route path="/details/:id" element={<Details />} />
                <Route path="/test" element={<Test />} />
                <Route path="/forum/question/:id" element={<ForumIndex />} />
            </Routes>
        </Router>
    )
}

export default AppRouter