import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import request from "../../services/request";
import LoadingButton from '@mui/lab/LoadingButton';
import {AlertContext} from "../base/AlertBox";
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function EditLinkContent(props) {
    //标题
    const [title, setTitle] = useState('');
    //内容
    const [content, setContent] = useState('')
    //按钮状态
    const [loading, setLoading] = useState(false);
    //提示框
    const {alertOpen} = useContext(AlertContext)


    useEffect(() => {
        //远程获取数据
        request({
            url: `/review/content/get?contentId=${props.contentId}`,
            method: 'get'
        }).then(response => {
            console.log('response', response);
            if(response.code === 200){
                if(response.data){
                    setTitle(response.data.name)
                    setContent(response.data.content)
                }else {
                    alertOpen('链接不存在哦~')
                }
            }else {
                alertOpen(response.data);
            }
        })
    },[])

    //提交
    const handleSubmit = (event) => {
        event.preventDefault();
        if(title === ''){
            alertOpen('warning', '请输入标题！')
            return;
        }
        if(content === ''){
            alertOpen('warning', '请输入链接！')
            return;
        }
        setLoading(true)
        request({
            url: '/review/content/update',
            method: 'post',
            data: JSON.stringify({
                "id": props.contentId,
                "name": title,
                "content": content
            })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '更新成功')
                setLoading(false)
                props.onSuccess();
            }else {
                alertOpen('error', response.msg)
            }
            setLoading(false);
        })
    };

    return (
        <Container component="main" sx={{backgroundColor: '#ffffff'}}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="title"
                    name="title"
                    label="复习标题"
                    autoComplete="title"
                    // autoFocus
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
                <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="title"
                    name="title"
                    label="链接地址"
                    autoComplete="title"
                    value={content}
                    onChange={(event) => setContent(event.target.value)}
                />
                <Box sx={{margin: '20px'}}>
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={6}
                    >
                        <Button fullWidth variant="outlined" onClick={props.onCancel}>
                            取消
                        </Button>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            loading={loading}
                            sx={{ mt: 3, mb: 2}}
                        >
                            修改
                        </LoadingButton>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}

EditLinkContent.propTypes = {
    contentId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}