import {useState, useEffect} from 'react';
import request from "../../../../services/request";
import ReplyItem from "./reply-item/ReplyItem";

import style from "../comment.module.scss";

const firstShowCount = 5;

export default function Reply({commentId}) {

    const [list, setList] = useState([]);

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        initData();
    }, [])

    async function initData() {
        const data = await getData(1);
        setList(data.list);
    }


    async function getData(pageNum) {
        const {code, data} = await request({
            url: `/topic/getReplyList?commentId=${commentId}&pageNum=1&pageSize=99999999`,
            method: 'get'
        })
        if (code === 200){
            return data;
        }else {
            return [];
        }
    }
    return (
        <>
            {(list.length <= firstShowCount || showAll)?
                list.map((reply) => {
                    return <li key={reply.id} className={style.childComment}><ReplyItem data={reply}/> </li>
                }) :
                list.slice(0, firstShowCount).map((reply) => {
                    return <li key={reply.id} className={style.childComment}><ReplyItem data={reply}/> </li>
                })
            }
            {
                (list.length > firstShowCount && !showAll) ?
                <div className={style.commentBtnWrap}>
                    <div className={style.commentBtnWrap}>
                        <button onClick={() => {setShowAll(true)}}>展开其他 {list.length - firstShowCount} 条回复</button>
                    </div>
                </div> :
                <div className={style.commentBtnWrap}>
                    <div className={style.commentBtnWrap}>
                        <button onClick={() => {setShowAll(false)}}>收起</button>
                    </div>
                </div>
            }
            {/*  是否展开*/}
            {/*{list.length > 5 ?*/}
            {/*    <div className={style.commentBtnWrap}>*/}
            {/*        /!*<button onClick={e => this.handleComments(e, rootId, comment)}>*!/*/}
            {/*        /!*    查看全部 {7} 条回复*!/*/}
            {/*        /!*</button>*!/*/}
            {/*        <button onClick={e => {}}>*/}
            {/*            查看全部 {list.length} 条回复*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    : (*/}
            {/*        list.length > 2 ?*/}
            {/*            <div className={style.commentBtnWrap}>*/}

            {/*                <button>展开其他 {list.length - 2} 条回复</button>*/}
            {/*            </div> : null*/}
            {/*    )*/}
            {/*}*/}
        </>
    )
}