import {forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function DialogBox(props) {


    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={() => props.setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            {props.children}
        </Dialog>
    );
}

DialogBox.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired
}

export default DialogBox