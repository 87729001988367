import {forwardRef} from 'react';
import Stack from '@mui/material/Stack';
import RefreshIcon from "./RefreshButton";
import '../css/refresh.css'
import '../css/custom-tab.css'

function CustomTab(props, ref) {
    return (
        <div className={props.selected ? "custom-tab custom-tab-selected" : "custom-tab"} ref={ref} >
            <Stack onClick={props.onClick} sx={{width: '100%', height: '100%'}} direction="row" justifyContent="center" alignItems="center" spacing={1} >
                <span>{props.title}</span>
                {/*{props.selected ? <RefreshIcon refresh={props.onRefresh} /> : <div style={{width: '20px', height: '20px'}}></div>}*/}
            </Stack>
        </div>
    )
}

export default forwardRef(CustomTab);