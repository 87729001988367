import {useState, useContext} from 'react'
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";
import IconButton from '@mui/material/IconButton';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import DraggableDialog from "../DraggableDialog";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DialogBox from "../base/DialogBox";
import EditLinkContent from "./EditLinkContent";



//加入错题本按钮
export function AddWrongBookButton(props) {

    const {alertOpen} = useContext(AlertContext)

    //添加到错题本
    function addWrongBookBtnClick(event, contentId) {
        request({
            url: '/review/wrongBook/add',
            method: 'post',
            data: JSON.stringify({ contentId })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '加入成功')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <Tooltip title="加入错题本">
            <IconButton color="secondary" aria-label="add an alarm" onClick={(event) => addWrongBookBtnClick(event, props.contentId)}>
                <BookmarkAddIcon />
            </IconButton>
        </Tooltip>
    )
}
AddWrongBookButton.propTypes = {
    contentId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
};



export function FinishReviewButton(props){

    const {alertOpen} = useContext(AlertContext)

    //完成复习
    function finishBtnClick(event, planId) {
        request({
            url: '/review/finishPlan',
            method: 'post',
            data: JSON.stringify({ planId })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '恭喜你，完成复习')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <Tooltip title="复习完成">
            <IconButton color="primary" aria-label="add to shopping cart" onClick={(event) => finishBtnClick(event, props.planId)}>
                <CheckCircleIcon />
            </IconButton>
        </Tooltip>
    )
}
FinishReviewButton.propTypes = {
    planId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}


export function NotReviewButton(props){

    const {alertOpen} = useContext(AlertContext)

    //完成复习
    function finishBtnClick(event, planId) {
        request({
            url: '/review/noReviewPlan',
            method: 'post',
            data: JSON.stringify({ planId })
        }).then(response => {
            if(response.code === 200){
                // alertOpen('success', '修改完成')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <Tooltip title="未复习">
            <IconButton color="primary" aria-label="add to shopping cart" onClick={(event) => finishBtnClick(event, props.planId)}>
                <TimerTwoToneIcon />
            </IconButton>
        </Tooltip>
    )
}
NotReviewButton.propTypes = {
    planId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}

export function CancelReviewButton(props){

    const {alertOpen} = useContext(AlertContext)

    function cancelBtnClick(event, planId) {
        request({
            url: '/review/cancelPlan',
            method: 'post',
            data: JSON.stringify({ planId })
        }).then(response => {
            if(response.code === 200){
                // alertOpen('success', '取消成功')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <Tooltip title="跳过本次复习" onClick={(event) => cancelBtnClick(event, props.planId)}>
            <IconButton aria-label="add to shopping cart">
                <CancelSharpIcon />
            </IconButton>
        </Tooltip>
    )
}
CancelReviewButton.propTypes = {
    planId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}

export function DeleteWrongBookButton(props){

    const {alertOpen} = useContext(AlertContext)

    //删除
    function deleteWrongBook(event, wrongBookId) {
        request({
            url: '/review/deleteWrongBook',
            method: 'post',
            data: JSON.stringify({ wrongBookId })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '删除成功')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <Tooltip title="删除" onClick={(event) => deleteWrongBook(event, props.wrongBookId)}>
            <IconButton aria-label=''>
                <DeleteForeverIcon />
            </IconButton>
        </Tooltip>
    )
}
DeleteWrongBookButton.propTypes = {
    wrongBookId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}



export function DeleteContentButton(props){

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const {alertOpen} = useContext(AlertContext)

    function handleOK() {
        setOpen(false);
        request({
            url: '/review/content/delete',
            method: 'post',
            data: JSON.stringify({ contentId: props.contentId })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '删除成功')
                if (props.onSuccess){
                    props.onSuccess();
                }
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        // onClick(event, props.wrongBookId)
        <>
            <DraggableDialog open={open} handleClose={handleClose} handleOK={handleOK}/>
            <Tooltip title="删除" onClick={(event) => setOpen(true)}>
                <IconButton aria-label=''>
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
        </>

    )
}
DeleteContentButton.propTypes = {
    contentId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}



export function EditContentButton(props){

    //编辑链接页面是否打开
    const [open, setOpen] = useState(false);


    function onClick() {
        //链接类型
        if(props.contentType === 0){
            setOpen(true)
            return;
        }
        //文本类型
        if (props.contentType === 1){
            window.open('_black').location.href = `/edit/${props.contentId}`
            return
        }
    }

    function onSuccess() {
        props.onSuccess();
        setOpen(false);
    }

    return (
        <>
            <DialogBox open={open} setOpen={setOpen} > <EditLinkContent contentId={props.contentId} onSuccess={onSuccess}  onCancel={() => setOpen(false)}/> </DialogBox>
            <Tooltip title="编辑" onClick={onClick}>
                <IconButton aria-label=''>
                    <EditSharpIcon />
                </IconButton>
            </Tooltip>
        </>

    )
}
EditContentButton.propTypes = {
    contentId: PropTypes.number.isRequired,
    contentType: PropTypes.number.isRequired,
    onSuccess: PropTypes.func
}


export function TitleShowButton(props){

    function onClick() {
        //链接类型
        if(props.contentType === 0){
            if(props.content.startsWith("https://") || props.content.startsWith("http://")){
                window.open(props.content)
            }else {
                window.open(`http://${props.content}`);
            }
        }

        //文本类型
        if(props.contentType === 1){
            window.open(`/details/${props.contentId}`)
        }

    }

    return (
        <div style={{width: "100%", display: "flex", alignItems: "center", cursor: "pointer"}} onClick={onClick}>{props.title}</div>
    )
}
TitleShowButton.propTypes = {
    contentId: PropTypes.number.isRequired,
    content: PropTypes.string,
    contentType: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
}