import Stack from '@mui/material/Stack'
import '../css/font-style.css'

function Logo() {
    return (
        <Stack sx={{height: '48px'}} justifyContent="center">
            <div className="logo"><span>⚡</span>爱复习</div>
        </Stack>
    )
}

export default Logo