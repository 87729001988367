import {createContext, useState, forwardRef} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const AlertContext = createContext();

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AlertBox(props) {
    //是否打开提示框
    const [alertState, setAlertState] = useState({open: false, severity: '', msg: ''});

    function alertOpen(severity, msg) {
        alertClose();
        setAlertState({open: true, severity, msg})
    }

    function alertClose() {
        setAlertState({open: false, severity: alertState.severity, msg: alertState.msg});
    }
    return (
        <>
            <Snackbar open={alertState.open} autoHideDuration={6000} onClose={alertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={alertClose} severity={alertState.severity} sx={{ width: '100%' }}>
                    {alertState.msg}
                </Alert>
            </Snackbar>
            <AlertContext.Provider value={{alertOpen, alertClose}}>
                {props.children}
            </AlertContext.Provider>
        </>
    )
}

export default AlertBox