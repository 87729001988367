import React, { useContext } from 'react'
import CommentIcon from '../../assets/svg/CommentIcon'
import ShareIcon from '../../assets/svg/ShareIcon'
import ArrowUp from '../../assets/svg/ArrowUp'

import styles from './content-actions.module.scss'
import ThumbUpIcon from "../../assets/svg/ThumbUp";
import request from "../../../services/request";
import {AlertContext} from "../../../components/base/AlertBox";

function ContentActions(props) {

    //提示框
    const {alertOpen} = useContext(AlertContext)

    const {data, closeFullContent, handleComment, fullContent, showComment, refresh} = props;

    const {id, praise, isPraise} = data;

    const switchPraise = () => {
        request({
            url: isPraise === 1 ? '/topic/cancelAnswerPraise' : '/topic/addAnswerPraise',
            method: 'post',
            data: JSON.stringify({
                answerId: id
            })
        }).then(response => {
            if(response.code === 200){
                refresh();
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    return (
        <div className={styles.wrapper} >
            <button className={styles.btn}>
                {
                    !showComment ? (
                        <div className={styles.commentWraper} onClick={handleComment}>
                            <span className={styles.icon}><CommentIcon /></span>
                            {`查看评论`}
                        </div>
                    ) : (
                        <div className={styles.commentWraper} onClick={handleComment}>
                            <span className={styles.icon}><CommentIcon /></span>
                            {`收起评论`}
                        </div>
                    )
                }
            </button>
            <button className={`${styles.btn} ${styles.btnShare}`}>
                <span className={styles.icon}><ShareIcon /></span>
                分享
            </button>
            <button className={`${styles.btn} ${styles.btnLike}`} style={isPraise === 1 ? {color: 'rgba(255, 161, 22, 1)'} : {}} onClick={switchPraise}>
                <span className={styles.icon}><ThumbUpIcon /></span>
                {`赞 ${praise}`}
            </button>
            {fullContent ? (
                <button className={`${styles.btn} ${styles.collapseBtn}`} onClick={closeFullContent}>
                    收起
                    <span className={styles.icon}><ArrowUp /></span>
                </button>
            ) : null}
        </div>
    );
}


export default ContentActions;