import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import request from "../services/request";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../css/preview.css";

function Details() {

    const { id } = useParams();

    const [data, setData] = useState(null);

    useEffect(() => {
        request({
            url: `/review/content/get?contentId=${id}`,
            method: 'get'
        }).then(response => {
            console.log('response', response);
            if(response.code === 200){
                if(response.data){
                    setData(response.data.content)
                }else {
                    setData('<div style="text-align: center; margin-top: 20px">文档不存在哦~</div>')
                }
            }else {

            }
        })
    }, [])

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!data} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="container" dangerouslySetInnerHTML={{__html: data}}></div>

        </>
    )
}

export default Details