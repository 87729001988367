import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function AboutUs(props) {

    const onCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    联系我们
                </Typography>
                <h4>邮箱：fuxi_love@126.com</h4>
                <span style={{color: '#999999'}}>或者提交问题反馈，我们会第一时间联系您！</span>
                <Box noValidate sx={{ mt: 1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={8}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={onCancel}
                        >
                            取消
                        </Button>
                    </Stack>

                </Box>
            </Box>
        </Container>
    );
}