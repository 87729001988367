import React, {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import request from "../../services/request";
import LoadingButton from '@mui/lab/LoadingButton';
import {AlertContext} from "../base/AlertBox";
import TextField from '@mui/material/TextField';
import RoundsGroup from "./RoundsGroup";

export default function AddLinkForm({refresh}) {
    //标题
    const [title, setTitle] = useState('');
    //内容
    const [content, setContent] = useState('')
    //轮次
    const [rounds, setRounds] = useState([])
    //按钮状态
    const [loading, setLoading] = useState(false);
    //提示框
    const {alertOpen} = useContext(AlertContext)

    //提交
    const handleSubmit = (event) => {
        event.preventDefault();
        if(title === ''){
            alertOpen('warning', '请输入标题！')
            return;
        }
        if(content === ''){
            alertOpen('warning', '请输入链接！')
            return;
        }
        if(rounds.length === 0){
            alertOpen('warning', '请选择复习轮次！')
            return;
        }
        setLoading(true)
        request({
            url: '/review/add/link',
            method: 'post',
            data: JSON.stringify({
                name: title,
                content: content,
                rounds: rounds
            })
        }).then(response => {
            if(response.code === 200){
                alertOpen('success', '添加成功！')
                setTitle('')
                setContent('')
                refresh && refresh();
            }else {
                alertOpen('error', response.msg)
            }
            setLoading(false);
        })
    };

    return (
        <Container component="main" sx={{backgroundColor: '#ffffff'}}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="title"
                    name="title"
                    label="复习标题"
                    autoComplete="title"
                    // autoFocus
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />
                <TextField
                    margin="normal"
                    // required
                    fullWidth
                    id="title"
                    name="title"
                    label="链接地址"
                    autoComplete="title"
                    value={content}
                    onChange={(event) => setContent(event.target.value)}
                />
                {/*轮次选择*/}
                <RoundsGroup rounds={{rounds, setRounds}}/>

                <Box sx={{margin: '0 70px'}}>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        loading={loading}
                        sx={{ mt: 3, mb: 2}}
                    >
                        添加复习内容
                    </LoadingButton>
                </Box>
            </Box>
        </Container>
    );
}