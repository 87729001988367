import {useState} from 'react';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Login from './Login';
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

export default function Auth(props) {

    const { onClose, open } = props;

    const [panel, setPanel] = useState(0);

    return (
        <Dialog onClose={onClose} open={open}>
            {panel === 0 && <Login setPanel={setPanel} />}
            {panel === 1 && <Register setPanel={setPanel} />}
            {panel === 2 && <ForgetPassword setPanel={setPanel} />}

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginBottom: 6,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        {
                            panel !== 2 &&

                                <Link onClick={() => setPanel(2)} variant="body2">
                                    忘记密码
                                </Link>

                        }
                        {
                            panel !== 1 &&

                                <Link onClick={() => setPanel(1)} variant="body2">
                                    注册
                                </Link>

                        }
                        {
                            panel !== 0 &&

                                <Link onClick={() => setPanel(0)} variant="body2">
                                    登录
                                </Link>

                        }
                    </Stack>
                    {/*<Box component="div" noValidate sx={{ mt: 1 }}>*/}
                    {/*    <Grid container>*/}
                    {/*        */}
                    {/*    </Grid>*/}
                    {/*</Box>*/}
                </Box>
            </Container>

            <div style={{position: "fixed", zIndex: "1301", left: "0", bottom: "10px", margin: "auto", width: "100%", textAlign: "center"}}>
                <Link  color="inherit" href="https://beian.miit.gov.cn/">
                    京ICP备2021021744号
                </Link>
            </div>

        </Dialog>
    );
}

Auth.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};