import {useState, useContext} from 'react';
import AuthorInfo from "../author-info/AuthorInfo";
import AnswerDetail from "../answer-detail/AnswerDetail";
import ArrowDown from '../../assets/svg/ArrowDown';
import ContentActions from '../content-actions/ContentActions'
import Comment from "../comment/Comment";

import style from './answer-item.module.scss'
import {AlertContext} from "../../../components/base/AlertBox";

export default function AnswerItem({data, refresh}) {

    //提示框
    const {alertOpen} = useContext(AlertContext)

    //显示全部内容
    const [fullContent, setFullContent] = useState(data.content.length < 100 ? true : false);

    //是否显示评论
    const [showComment, setShwoComment] = useState(false);


    const showFullContent = () => {
        setFullContent(!fullContent);
    }

    const closeFullContent = () => {
        setFullContent(false);
    }

    const handleComment = () => {
        alertOpen('error', '功能正在开发中...');
        return;
        setShwoComment(!showComment);
    }

    return (
        <div className={style.card}>
            {/* 头部标记 */}
            {/*<div ref={ref => this.titleRef = ref}></div>*/}
            {/* 回答 */}
            <div className={style.answerItem}>
                <div onClick={e => showFullContent()}>
                    <div className={`${fullContent? null : style.answerBrief}`}>
                        <div>
                            <AuthorInfo data={data} />
                            <AnswerDetail data={data} />
                        </div>
                    </div>
                    <button className={fullContent? style.btnHidden : style.btn}>
                        展开阅读全文 <span>​​​​<ArrowDown /></span></button>
                </div>
                {/* 回答点赞评论栏 */}
                <div className={style.contentActions}>
                    <ContentActions refresh={refresh} data={data} fullContent={fullContent} showComment={showComment} handleComment={handleComment} closeFullContent={closeFullContent}/>
                </div>
                {
                    showComment && (
                        <Comment answerId={data.id}/>
                    )
                }
            </div>
        </div>
    )
}