import React, { Component } from 'react';
import moment from 'moment';

import style from './author-info.module.scss'

export default function AuthorInfo({data}) {

    const {updateTime, userInfo:{nickname, portrait}} = data;

    return (
        <div className={style.author}>
            <img className={style.avator} src={portrait} alt="头像" />
            <div className={style.authorWrap}>
                <div className={style.authorName}>{nickname}</div>
                <div className={style.authorHeadline}>回答时间：{moment(updateTime).format('YYYY-MM-DD HH:mm')}</div>
            </div>

        </div>
    )
}