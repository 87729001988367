import React, { useState } from 'react'

import { ReportIcon } from "../../../assets/svg/ReportIcon"
import ThumbUp from "../../../assets/svg/ThumbUp"
import { ThumbDown } from "../../../assets/svg/ThumbDown"
import { ReplayIcon } from "../../../assets/svg/ReplayIcon"
import moment from 'moment';


import style from "./comment-item.module.scss"
import CommentIcon from "../../../assets/svg/CommentIcon";
import Reply from "../reply/Reply";
import CommentEdit from "../comment-edit/CommentEdit";
import ReplyEdit from "../reply/reply-edit/ReplyEdit";

function CommentItem({data}) {

    const [showReply, setShowReply] = useState(false);

    const [openReply, setOpenReply] = useState(false);

    const switchShowReply = () => {
        setShowReply(!showReply);
    }

    const switchOpenReply = () => {
        setOpenReply(!openReply);
    }

    const {content, createTime, praise} = data;
    return (
        <>
            <div className={style.commentItem}>
                <div className={style.commentMeta}>
                    <span className={style.avator}><img src={'https://pic1.zhimg.com/v2-774473e9be037a05327dd1e2df985a4c_l.jpg?source=1940ef5c'} alt="avatar" /></span>
                    <span className={style.name}>{'读旅世界'}</span>
                    {false &&
                    <span>
                        <span className={style.replay}> 回复 </span>
                        <span className={style.name}>{'replayName'}</span>
                    </span>
                    }
                    <span className={style.editTime}>{moment(createTime).format('YYYY-MM-DD')}</span>
                </div>
                <div className={style.commentWrap}>
                    <div className={style.comment}>
                        <span className={style.fullContent} dangerouslySetInnerHTML={{ __html: content }}></span>
                        {/* <span>{content}</span> */}
                    </div>
                    <div className={style.footer}>
                        <button className={style.btn}>
                            <span className={style.icon}><ThumbUp /></span>
                            {praise}
                        </button>
                        <button className={style.btn} onClick={switchShowReply}>
                            <span className={style.icon}><CommentIcon /></span>
                            {showReply ? '收起回复' : '查看回复'}
                        </button>
                        <button className={style.btn} onClick={switchOpenReply}>
                            <span className={style.icon}><ReplayIcon /></span>
                            {openReply ? '取消回复' : '回复'}
                        </button>
                        <button className={style.btn}>
                            <span className={style.icon}><ThumbDown /></span>
                            踩
                        </button>
                        <button className={style.btn}>
                            <span className={style.icon}><ReportIcon /></span>
                            举报
                        </button>
                    </div>
                    {openReply &&
                        <div>
                            <ReplyEdit/>
                        </div>
                    }
                </div>
            </div >
            {/* 子评论 */}
            {showReply && <Reply commentId={data.id}/>}
        </>

    )
}

export default CommentItem;