import * as React from 'react';
import Stack from '@mui/material/Stack';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";
import LocalStorageUtils from "../../utils/localStorageUtils";

export default function Discussion() {

    //提示框
    const {alertOpen} = React.useContext(AlertContext)

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };


    const [list, setList] = React.useState([]);

    function onClick(url) {
        window.open(`/forum/question/${url}`);
    }

    React.useEffect(() => {
        if (LocalStorageUtils.getInstance().getToken()){
            request({
                url: '/topic/getQuestionList?pageNum=1&pageSize=5',
                method: 'get'
            }).then(response => {
                if(response.code === 200){
                    setList(response.data)
                }else {
                    alertOpen('error', response.msg)
                }
            })
        }
    }, [])

    return (
        <>
            <ListSubheader component="div" id="nested-list-subheader">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <div>每日学习话题榜</div>
                    {open ? <ExpandLess onClick={handleClick}/> : <ExpandMore onClick={handleClick}/>}
                </Stack>
            </ListSubheader>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {
                        list.map((item, index) => {
                            return (
                                <ListItemButton key={item.id} onClick={() => {onClick(item.id)}}>
                                    <ListItemIcon>
                                        <span style={{padding: '0 12px', fontSize: '16px', fontWeight: 700, color: '#f26d5f'}}>{index + 1}</span>
                                    </ListItemIcon>
                                    <ListItemText style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} primary={item.title} />
                                    {/*<span className="wbpro-icon-search-tp1" style={{width: '20px', height: '20px', lineHeight: '20px', fontSize: '14px', textAlign: 'center', background: 'rgb(255, 148, 6)', borderRadius: '4px', color: '#fff'}}>热</span>*/}
                                </ListItemButton>
                            )
                        })
                    }
                </List>
            </Collapse>
        </>
    );
}