import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import request from "../services/request";


function SearchReviewContent() {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    //获取列表数据
    function initData() {
        request({
            url: `/review/getReviewContentList`,
            method: 'post',
            data: JSON.stringify({})
        }).then(response => {
            if(response.code === 200){
                setOptions(response.data.list)
            }else {
            }
        })
    }

    useEffect(() => {
        if (!loading) {
            return undefined;
        }

        initData();

        return () => {
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);


    return (
        <Autocomplete
            id="search-content"
            freeSolo
            sx={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => {
                return option.name ? option.name : option;
            }}
            options={options}
            loading={loading}
            onChange={(event, value, reason, details) => {
                if(reason === 'selectOption'){
                    //跳转到详情页
                    if(value.type === 0){
                        if(value.content.startsWith("https://") || value.content.startsWith("http://")){
                            window.open('_black').location.href = value.content
                        }else {
                            window.open('_black').location.href = `http://${value.content}`
                        }
                        return
                    }
                    if(value.type === 1){
                        window.open('_black').location.href = `/details/${value.id}`
                        return;
                    }

                }
                //创建选项可以进行搜索内容
                if(reason === 'createOption'){
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="搜索"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export default SearchReviewContent