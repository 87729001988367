import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        // 第一主题色
        primary: {
            main: 'rgba(0, 104, 217, 1)',
        },
        // 第二主题色
        secondary: {
            main: 'rgba(255, 161, 22, 1)'
        },
        //
        grey: {
            main: '#bdbdbd',
        },
    }
});


function AppTheme({children}){
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default AppTheme