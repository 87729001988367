import {useState, useEffect} from 'react';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import '../../../css/rich-text-editor.css'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import preview from "../../../utils/previewHtml";
import LocalStorageUtils from "../../../utils/localStorageUtils";
import request from "../../../services/request";

function AnswerRichTextEditor(props) {
    //全屏显示的控制器
    const fullscreenControls = [
        // {
        //     key: 'content-type',
        //     type: 'dropdown',
        //     title: '复习内容类型',
        //     text: '文本',
        //     component: (<div>
        //         <div style={{color: '#fff', padding: 10}}>文本</div>
        //         <div style={{color: '#fff', padding: 10}}>链接</div>
        //     </div>)
        // },
        // 'separator',
        // {
        //     key: 'save-button',
        //     type: 'button',
        //     title: '保存',
        //     text: <SdStorageOutlinedIcon fontSize="small"/>,
        //     onClick: onSave
        // },
        'undo', 'redo', 'separator',
        'font-size', 'line-height', 'letter-spacing', 'separator',
        'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
        'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'separator', 'hr', 'separator',
        'media', 'separator',
        'clear',
        {
            key: 'preview-button',
            type: 'button',
            title: '预览',
            text: <VisibilityOutlinedIcon fontSize="small"/>,
            onClick: onPreviewClick
        },
        'fullscreen',
    ]

    //编辑状态
    const [editorState, setEditorState] = props.editorState;//<p><strong>标题</strong></p><hr/><p></p>

    function onPreviewClick() {
        preview(editorState.toHTML(), '答案预览')
    }
    
    function editorStateChange(editorState) {
        setEditorState(editorState);
    }


    const myUploadFn = (param) => {
        const formdata = new FormData()
        formdata.append("file", param.file)
        request({
            url: '/file/upload',
            method: 'post',
            headers:{'Content-Type':'multipart/form-data'},
            data: formdata,
            onUploadProgress: progressEvent => {
                const persent = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
                param.progress(persent);
            },
            timeout: 100000000 //一秒钟
        }).then(response => {
            if(response.code === 200){
                param.success({
                    url: response.data,
                    meta: {
                        id: response.data,
                        // title: 'xxx',
                        // alt: 'xxx',
                        // loop: true, // 指定音视频是否循环播放
                        // autoPlay: true, // 指定音视频是否自动播放
                        // controls: true, // 指定音视频是否显示控制栏
                        // poster: 'http://xxx/xx.png', // 指定视频播放器的封面
                    }
                })
            }else {
                param.error({
                    msg: response.data
                })
            }
        })
    }


    return <BraftEditor
        controls={fullscreenControls}
        value={editorState}
        media={{uploadFn: myUploadFn}}
        onChange={editorStateChange}
    />
}

export default AnswerRichTextEditor