import {useState, useEffect, useContext} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import request from "../../services/request";
import {AlertContext} from "../base/AlertBox";
import NotReviewPlan from "./NotReviewPlan";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import '../../css/accordion-summary.css'
import Divider from '@mui/material/Divider';
import LocalStorageUtils from "../../utils/localStorageUtils";

function NotReviewedWarning() {

    const [count, setCount] = useState(0);

    //提示框
    const {alertOpen} = useContext(AlertContext)

    useEffect(() => {
        if(LocalStorageUtils.getInstance().getToken()){
            request({
                url: `/review/getBeforeNoReviewPlanCount`,
                method: 'get',
                data: JSON.stringify({})
            }).then(response => {
                if(response.code === 200){
                    setCount(response.data);
                }else {
                    alertOpen('error', response.msg)
                }
            })
        }
    }, [])

    return (
        count <= 0 ? <></> :
        <div>
            {/* 大于50则初始不渲染 */}
            <Accordion TransitionProps={{ unmountOnExit: count >= 50 }}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{padding: 0, margin: 0}}
                >
                    <Alert severity="warning" style={{width: '100%'}}>
                        <AlertTitle>未复习提示</AlertTitle>
                        您之前有 {count} 个未复习的内容哦！ —
                        <strong style={{cursor: "pointer", padding: '10px 4px'}}>点击展开/折叠</strong>
                    </Alert>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0'}}>
                    <NotReviewPlan />
                    <Divider sx={{marginTop: '20px', marginBottom: '40px'}}>
                        <font color='#999999'>以上是未记忆的内容</font >
                    </Divider>
                </AccordionDetails>
            </Accordion>
        </div>

    )
}

export default NotReviewedWarning