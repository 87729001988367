import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {StyledTableCell} from "./StyledTable";
import {AlertContext} from "../base/AlertBox";
import request from "../../services/request";
import LocalStorageUtils from "../../utils/localStorageUtils";
import {AddWrongBookButton, DeleteContentButton, EditContentButton, TitleShowButton} from "./OperationButton";
import Stack from '@mui/material/Stack';
import {ContentStateShow, ContentTypeShow, PlanStateShow} from "./StateAndTypeShow";
import moment from 'moment';
import TablePagination from '@mui/material/TablePagination';
import {SearchInput} from "./CostomInput";


function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    function operation(row) {
        return (
            <Stack direction="row"
                   justifyContent="center"
                   alignItems="baseline"
                   spacing={1} >
                <DeleteContentButton contentId={row.id} onSuccess={props.refresh}/>
                <AddWrongBookButton contentId={row.id} />
                <EditContentButton contentId={row.id} contentType={row.type} onSuccess={props.refresh}/>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/*<TableCell component="th" scope="row" sx={{cursor: 'pointer'}} onClick={() => onClick(row)}>*/}
                {/*    {row.name}*/}
                {/*</TableCell>*/}
                <TableCell component="th" scope="row" sx={{cursor: 'pointer'}}>
                    <TitleShowButton contentId={row.id} contentType={row.type} title={row.name} content={row.content} />
                </TableCell>
                <TableCell align="left"><ContentTypeShow type={row.type} /></TableCell>
                <TableCell align="left">{row.rounds}</TableCell>
                <TableCell align="left">{moment(row.createTime).format('YYYY/MM/DD')}</TableCell>
                <TableCell align="left"><ContentStateShow state={row.state} /></TableCell>
                <TableCell align="left">{operation(row)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 , background: '#f7f8fa'}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                复习过程
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>轮次</TableCell>
                                        <TableCell>状态</TableCell>
                                        <TableCell>复习时间</TableCell>
                                        <TableCell>完成时间</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.reviewPlans.map((reviewPlan) => (
                                        <TableRow key={reviewPlan.contentId}>
                                            <TableCell component="th" scope="row">
                                                {reviewPlan.currentRounds}
                                            </TableCell>
                                            <TableCell><PlanStateShow state={reviewPlan.state} /></TableCell>
                                            <TableCell>{moment(reviewPlan.reviewTime).format('YYYY/MM/DD')}</TableCell>
                                            <TableCell>{reviewPlan.finishTime ? moment(reviewPlan.finishTime).format('YYYY/MM/DD HH:mm') : '无'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function AllReviewContent(props, ref) {

    //计划列表数据
    const [data, setData] = useState([]);
    //当前页
    const [page, setPage] = useState(0);
    //每页数量
    const [rowsPerPage, setRowsPerPage] = useState(10);
    //总数据量
    const [total, setTotal] = useState(0);
    //提示框
    const {alertOpen} = useContext(AlertContext)

    function handleChangeRowsPerPage(event) {
        const pageSize = parseInt(event.target.value, 10);
        setRowsPerPage(pageSize);
        LocalStorageUtils.getInstance().setItem('allReviewPageSize', pageSize);
        setPage(0);
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
        document.body.scrollTo(0, 0);
    };

    //获取列表数据
    function initData(title = '', state = null, type = null, ) {
        request({
            url: `/review/getReviewContentList`,
            method: 'post',
            data: JSON.stringify({
                "pageNo": page + 1,
                "pageSize": rowsPerPage,
                "leftMatch": 0,
                "title": title
            })
        }).then(response => {
            if(response.code === 200){
                setData(response.data.list)
                setTotal(response.data.total)
            }else {
                alertOpen('error', response.msg)
            }
        })
    }

    useEffect(() => {
        //获取列表数据
        if (LocalStorageUtils.getInstance().getToken()){
            const pageSize = LocalStorageUtils.getInstance().getItem('allReviewPageSize');
            if(pageSize){
                setRowsPerPage( pageSize);
            }
            initData();
        }
    }, [page, rowsPerPage])

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        refreshData: initData
    }));



    function defaultGetAriaLabel(type) {
        if(type === 'first'){
            return '首页'
        }
        if(type === 'last'){
            return '尾页'
        }
        if(type === 'next'){
            return '下一页'
        }
        if(type === 'previous'){
            return '上一页'
        }
    }



    return (
        <>
            <Stack direction="row"
                   justifyContent="space-between"
                   alignItems="center"
                   spacing={1}
                   sx={{pt: 2, ml: 1, mr: 1}}>
                <Box sx={{ml: 2, color: '#777777'}}>内容数量：<span style={{color: 'rgba(255, 161, 22, 1)'}}>{total}</span> 个</Box>
                {/*<Stack direction="row"*/}
                {/*       justifyContent="start"*/}
                {/*       alignItems="center"*/}
                {/*       spacing={1}>*/}
                {/*    <SelectBox label="类型" data={["链接", "文本"]}/>*/}
                {/*    <SelectBox label="状态" data={["复习中", "完成"]}/>*/}
                {/*</Stack>*/}
                <SearchInput onSearch={title => initData(title)}/>
            </Stack>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <StyledTableCell align="left">标题</StyledTableCell>
                            <StyledTableCell align="left">类型</StyledTableCell>
                            <StyledTableCell align="left">轮次</StyledTableCell>
                            <StyledTableCell align="left">创建时间</StyledTableCell>
                            <StyledTableCell align="left">状态</StyledTableCell>
                            <StyledTableCell align="center">操作</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <Row refresh={initData} key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"每页显示行数"}
                getItemAriaLabel={defaultGetAriaLabel()}
            />
        </>
    );
}

export default forwardRef(AllReviewContent)