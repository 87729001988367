import {useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import request from "../../services/request";
import PropTypes from 'prop-types';
import {AlertContext} from "../base/AlertBox";
import LocalStorageUtils from "../../utils/localStorageUtils";
import sha256 from 'crypto-js/sha256';
import Stack from '@mui/material/Stack'

export default function Login(props) {
    //提示框
    const {alertOpen} = useContext(AlertContext)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const fromData = new FormData(event.currentTarget);
        request({
            url: '/user/login',
            method: 'post',
            data: JSON.stringify({
                name: fromData.get('email'),
                password: sha256(fromData.get('password')).toString(),
            })
        }).then(response => {
            //登录成功
            if(response.code === 200){
                LocalStorageUtils.setToken(response.data.token, response.data.sign)
                window.location.reload();
            }else{
                //登录失败
                alertOpen('error', response.msg)
            }
        })
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 4,
                    marginBottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stack justifyContent="center">
                    <div className="logo" style={{fontSize: '46px'}}><span>⚡</span>爱复习</div>
                </Stack>
                <br />
                <div style={{color: 'rgba(255, 161, 22, 0.8)', fontSize: '18px'}}>不再有易忘的痛苦</div>
                {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                {/*    /!*<LockOutlinedIcon />*!/*/}
                {/*    <Logo />*/}
                {/*</Avatar>*/}
                {/*<Typography component="h1" variant="h5">*/}
                {/*    爱复习*/}
                {/*</Typography>*/}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="账号或邮箱"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="密码"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary" />}*/}
                    {/*    label="记住我"*/}
                    {/*/>*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        登录
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}