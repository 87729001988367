import axios from 'axios'
import LocalStorageUtils from "../utils/localStorageUtils";

// 创建axios实例
const service = axios.create({
    baseURL: "https://fuxi.love/api",
    // baseURL: "http://127.0.0.1:9001",
    timeout: 50000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json' // 关键所在
        // if(typeof window !== 'undefined'){
        config.headers['token'] = LocalStorageUtils.getInstance().getToken()
        // }
        return config
    },
    error => {
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        return response.data;
        // if (res.code !== 200) { // 后台返回码，根据自己的业务进行修改
        //     return Promise.reject(res.data)
        // } else {
        //     return res.data
        // }
    },
    error => {
        console.log('err' + error) // for debug
        console.log(error)
        return Promise.reject(error)
    }
)

export default service