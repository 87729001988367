import {useState, useEffect} from 'react';
import CommentItem from "./comment-item/CommentItem";
import request from "../../../services/request";
import CommentEdit from "./comment-edit/CommentEdit";

import style from './comment.module.scss'
import Reply from "./reply/Reply";

export default function Comment({answerId}) {

    const [pageNum, setPageNum] = useState(1);

    const [list, setList] = useState([]);

    useEffect(() => {
        initData();
    }, [])

    async function initData() {
        const data = await getData(1);
        setList(data.list);
    }


    async function getData(pageNum) {
        const {code, data} = await request({
            url: `/topic/getCommentList?answerId=${answerId}&pageNum=${pageNum}&pageSize=20`,
            method: 'get'
        })
        if (code === 200){
            return data;
        }else {
            return [];
        }
    }

    // return (
    //     <>
    //         {/* 输入评论 */}
    //         <div>
    //             <CommentEdit />
    //         </div>
    //         {
    //             list.map(item => {
    //                 return <CommentItem data={item} />
    //             })
    //         }
    //     </>
    // )
    // return <CommentItem />
    return (
        <div className={style.comment}>
            {/* 评论区头部 */}
            {/*<div className={style.topBar} ref={ref => this.topRef = ref}>*/}
            {/*    <div className={style.topBarTitle}><h2>{this.state.commentsNum} 条评论</h2></div>*/}
            {/*    <div className={style.topBarOption}>*/}
            {/*        <button className={style.btn}>*/}
            {/*            <span><SwitchIcon/></span>切换为时间排序*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* 评论内容 */}
            <div>
                <div className={style.commentList}>
                    {list.map((comment, idx) => {
                        const {child_comments, replies, id: rootId} = comment
                        const res =
                            <ul key={idx} className={style.commentItem}>
                                {/* 父评论 */}
                                <li><CommentItem data={comment}/></li>
                                {/*/!* 子评论 *!/*/}
                                {/*<Reply commentId={comment.id}/>*/}
                            </ul>
                        return res
                    })}
                </div>
            </div>
            {/* 输入评论 */}
            <div>
                <CommentEdit/>
            </div>
        </div>
    )
}